import React from 'react';
import PropTypes from 'prop-types';

const NotFoundPage = ({ error }) => (
  <div>
    <h1>404: Page Not Found</h1>
    <p>{error || 'Sorry, the page you are looking for does not exist.'}</p>
  </div>
);

NotFoundPage.propTypes = {
  error: PropTypes.string,
};

NotFoundPage.defaultProps = {
  error: null,
};

export default NotFoundPage;
