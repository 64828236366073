// TeamRow.js
import React from 'react';
import PlayerNamePlate from './PlayerNamePlate';
import injuryIcon from '../../shared/assets/hospital.png';

const IRPlayerRow = ({ player }) => {

    return (
      <tr>
        <td className="name-td">
          <div className="flex-container">
            <PlayerNamePlate player={player} icon = {injuryIcon} />
          </div>
        </td>       

        <td>{player.salary}</td>
        <td>{player.contract_length}</td>
        <td>{player.salary_relief}</td>
        <td>{player.contract_relief}</td>

      </tr>
    );
  };
  
  export default IRPlayerRow;
  