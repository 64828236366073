import React from 'react';
import PlayerNamePlate from './PlayerNamePlate';
import { useParams } from 'react-router-dom';
import icon from '../../shared/assets/contract.png';

const EditableContractPlayerRow = ({ player, index, editablePlayers, setEditablePlayers, updateData , handleUpdate}) => {
  const { league_key, league_team_id } = useParams();

  const handleChange = async (field, value) => {
    if (value < 1 || value > 4) {
      alert("Contract length must be between 1 and 4.");
      return;
    }
    const updatedPlayers = [...editablePlayers];
    updatedPlayers[index][field] = value;
    setEditablePlayers(updatedPlayers);

        // Post the updated players to the Flask API
        try {
            const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/league/update_keepers/${league_key}/${league_team_id}`;
            const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedPlayers)
            });

            if (response.ok) {
            const data = await response.json();
            console.log("Successfully updated players:", data);

            updateData(data); // Passes to parent-parent
            handleUpdate(data); // Passes to parent <------------------------------------ I don't think I need to do this, but tired and fuck it
                console.log("editRow: ", data)
            } else {
            console.log("Failed to update players. Response code:", response.status);
            }
        } catch (error) {
            console.log("Error while updating players:", error);
        }
    };

  return (
    <tr>
      <td className="name-td">
        <PlayerNamePlate player={player} icon = {icon} />
      </td>
      <td>{player.salary}</td>
      <td>
        <input
          type="number"
          min="1"
          max="4"
          value={player.contract_length}
          onChange={(e) => handleChange('contract_length', parseInt(e.target.value))}
        />
      </td>
    </tr>
  );
};

export default EditableContractPlayerRow;
