import React from 'react';
import TeamCapSummary from './TeamCapSummary';

const TeamHeader = ({ team, team_cap_summary }) => {

    return (
        <div>
      

        <div className="team-title-container">
            <img 
              src={team?.team_logo_url} 
              alt={team?.team_name} 
              className="team-logo"
            />
            <h1>{team?.team_name}</h1>
          </div>
          <TeamCapSummary team_cap_summary={team_cap_summary}/>

                  {/* Conditionally display the "NOT CAP COMPLIANT" header */}
          {team_cap_summary && !team_cap_summary.cap_compliant && (
              <div style={{backgroundColor: '#bd6868', textAlign: 'center', fontWeight: 'bold'}}>
                  <h3><b>NOT CAP COMPLIANT</b></h3>
              </div>
           )}
          
        </div>
      );      
      
  };
  
export default TeamHeader;
