// TeamRow.js
const TeamRow = ({ team, icon }) => {
  // Check if cap_compliant exists and its value
  const isCapCompliant = team.team_cap_summary?.cap_compliant;
  console.log('isCapCompliant', isCapCompliant)
  return (
    <tr className={isCapCompliant === false ? 'league-team-non-cap-compliant' : ''}>
      <td>
      <img 
        src={team.team_logo_url} 
        alt={team.team_name} 
        style={{ borderRadius: '50%', width: '60px', height: '60px' }} 
      />
      </td>

      <td className="name-td">
          <a href={`${team.league_key}/${team.league_team_id}`}>
              {team.team_name}
          </a>
      {icon && (
        <div className="icon" style={{ display: 'inline-block', marginLeft: '8px' }}>
          <img src={icon} alt="circle icon" style={{ width: '14px', height: '14px' }} />
        </div>
      )}
      </td>
      <td style={{fontWeight: 'bold'}}>{team.team_cap_summary.available_cap}</td>
      <td style={{fontWeight: 'bold'}}>{team.team_cap_summary.available_contract_years}</td>
      <td>{team.team_cap_summary.ir_salary_relief}</td>
      <td>{team.team_cap_summary.ir_contract_years_relief}</td>
      <td>{team.team_cap_summary.cap_penalty}</td>
    </tr>
  );
};

export default TeamRow;
