import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import IRPlayerRow from './components/IRPlayerRow';

import LeagueNavbar from '../shared/LeagueNavbar';
import TeamHeader from './components/TeamHeader';

import './Team.css';
import DroppedPlayerRow from './components/DroppedPlayerRow';
import RosterPlayerRow from './components/RosterPlayerRow';
import UnsignedPlayerSection from './components/UnsignedPlayerSection';

const Team = () => {
  const { league_key, league_team_id } = useParams();
  const [data, setData] = useState(null);
  const [teamCapSummary, setTeamCapSummary] = useState(null);

  console.log(league_key, league_team_id);
  const updateData = (newData) => {
    setTeamCapSummary(newData);
    console.log("Updated data in Team.js:", newData);
  };

  useEffect(() => {
    // Using the environment variable here
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/league/${league_key}/${league_team_id}`;
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        setData(data);
        setTeamCapSummary(data?.team?.team_cap_summary);
      });
  }, [league_key, league_team_id]);

    useEffect(() => {
      console.log('Updated teamCapSummary:', teamCapSummary);
      }, [teamCapSummary]);

  return (
    <div>
      {/* Conditionally render LeagueNavbar */}
      {data && <LeagueNavbar teams={data.navbar_teams} league_key={league_key} />}

      <div className='page-content'>

        <TeamHeader team={data?.team} team_cap_summary={teamCapSummary} />

        <UnsignedPlayerSection unsigned_players={data?.unsigned_roster} updateData={updateData} team_cap_summary={teamCapSummary}/>

        
        <div>
          <Table bordered hover className='table'>
            <thead>
              <tr>
                <th className="name-th">Player</th>
                <th>Salary</th>
                <th>Years</th>
              </tr>
            </thead>
            <tbody>
              {data?.current_roster?.map((player_contract) => (
                <RosterPlayerRow key={player_contract.player_key} player={player_contract} />
              ))}
            </tbody>
          </Table>
        </div>

        {data?.ir_players?.length > 0 ? (
        <div className="IR-table">
          <h3> IR Players </h3>
          <Table bordered hover className='table'>
            <thead>
              <tr>
                <th className="name-th">Player</th>
                <th>Salary</th>
                <th>Years</th>
                <th>Salary Relief</th>
                <th>Contract Relief</th>
              </tr>
            </thead>
            <tbody>
              {data?.ir_players?.map((player_contract) => (
                <IRPlayerRow key={player_contract.player_key} player={player_contract} />
              ))}
            </tbody>
          </Table>
        </div>
        ) : null}

      {data?.dropped_players?.length > 0 ? (
      <div>
        <h3> Dropped Players </h3>
        <Table bordered hover className='table'>
          <thead>
            <tr>
              <th className="name-th">Player</th>
              <th>Salary</th>
              <th>Years</th>
              <th>Cap Penalty</th>
            </tr>
          </thead>
          <tbody>
            {data?.dropped_players?.map((player_contract) => (
              <DroppedPlayerRow key={player_contract.player_key} player={player_contract} />
            ))}
          </tbody>
        </Table>
      </div>
      ) : null}

    </div>
  </div>
  );
};

export default Team;