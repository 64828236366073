// TeamRow.js
import React from 'react';
import PlayerNamePlate from './PlayerNamePlate';
import droppedIcon from '../../shared/assets/bin.png';

const DroppedPlayerRow = ({ player }) => {

    return (
      <tr>

        <td className="name-td">
            <div className="flex-container">
            <PlayerNamePlate player={player} icon = {droppedIcon} />
            </div>
        </td>      

        <td>{player.salary}</td>
        <td>{player.contract_length}</td>
        <td>{player.cap_penalty}</td>

      </tr>
    );
  };
  
  export default DroppedPlayerRow;
  