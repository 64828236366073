import React from 'react';
import PlayerNamePlate from '../../Team/components/PlayerNamePlate';
import WWIcon from '../../shared/assets/WW.png';

const ContractListPlayerRow = ({ player }) => {
    return (
      <tr>
        <td className="name-td">
            <PlayerNamePlate 
                player={player}
                icon={player.player_added ? WWIcon : undefined}
            />
        </td>
        <td>{player.salary}</td>
        <td>
          <img src={player.team_logo_url} alt={`${player.player_name} team logo`} className="team-logo" />
        </td>

      </tr>
    );
};

export default ContractListPlayerRow;
