import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';
import EditableContractPlayerRow from './EditableContractPlayerRow';
import Alert from 'react-bootstrap/Alert';

const UnsignedPlayerSection = ({ unsigned_players, updateData, team_cap_summary}) => {
  const [showModal, setShowModal] = useState(false);
  const [editablePlayers, setEditablePlayers] = useState(unsigned_players);
  const [teamCapSummary, setTeamCapSummary] = useState(team_cap_summary);
  const [showAlert, setShowAlert] = useState(false);

  const { league_key, league_team_id } = useParams();
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleUpdate = (newData) => {
    console.log("handleUpdate: ", newData)
    setTeamCapSummary(newData);
  }

    useEffect(() => {
        setTeamCapSummary(team_cap_summary);
    }, [team_cap_summary]);
  

  const handleSubmit = async () => {
   // Submit your changes here. `editablePlayers` contains the modified data.
    if (teamCapSummary.available_contract_years >= 0) {
        setShowAlert(false);

        try {
            // Using the environment variable here
            const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/league/confirm_keepers/${league_key}/${league_team_id}`;

            const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(editablePlayers),
            });
        
            if (response.ok) {
                console.log("Keepers confirmed")
                window.location.reload();
            } else {
            console.error("Failed to submit data:", await response.json());
            }
        } catch (error) {
            console.error("There was an error submitting the data:", error);
        }
    
        } else {
            setShowAlert(true);
        }
        
    handleClose();
  };

    useEffect(() => {
        if (unsigned_players) {
        setEditablePlayers(unsigned_players);
        }
    }, [unsigned_players]);

  return (
    
    <div className="unsigned_player_section">
        {showAlert && (
        <Alert variant="danger">
            Too many contract years are being used.
        </Alert>
        )}
      {unsigned_players?.length > 0 ? (
        <div>
          <div className="header-container">
            <h3>Unsigned contracts</h3>
            <Button onClick={handleShow}>Submit Keepers</Button>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to submit your contracts?</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Confirm
                </Button>
                </Modal.Footer>
            </Modal>
          </div>


          <Table bordered hover className='table'>
            <thead>
              <tr>
                <th className="name-th">Player</th>
                <th>Salary</th>
                <th>Years</th>
              </tr>
            </thead>
            <tbody>
              {editablePlayers?.map((player_contract, index) => (
                <EditableContractPlayerRow
                  key={player_contract.player_key}
                  index={index}
                  player={player_contract}
                  setEditablePlayers={setEditablePlayers}
                  editablePlayers={editablePlayers}
                  updateData={updateData}
                  handleUpdate={handleUpdate}
                />
              ))}
            </tbody>
          </Table>
          
        </div>
      ) : null}
    </div>
  );
};

export default UnsignedPlayerSection;
