// TeamRow.js
import React from 'react';

const TeamCapSummary = ({ team_cap_summary }) => {
    const salaryRow = [
        team_cap_summary?.current_cap,
        team_cap_summary?.total_salary,
        team_cap_summary?.ir_salary_relief,
        team_cap_summary?.cap_penalty,
        team_cap_summary?.available_cap
      ];
    
      const contractRow = [
        team_cap_summary?.contract_years,
        team_cap_summary?.total_contract_years,
        team_cap_summary?.ir_contract_years_relief,
        "",
        team_cap_summary?.available_contract_years
      ];

    return (
        <div>

        <table className='team_cap_summary_table'>
            <thead>
                <tr>
                <th></th>
                <th>Cap</th>
                <th>Spent</th>
                <th>IR Relief</th>
                <th>Cap Penalty</th>
                <th>Available</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>Salary</td>
                {salaryRow.map((value, index) => (
                    <td key={index}>{value}</td>
                ))}
                </tr>
                <tr>
                <td>Contract</td>
                {contractRow.map((value, index) => (
                    <td key={index}>{value}</td>
                ))}
                </tr>
            </tbody>
            </table>

        </div>



      );      
      
  };
  
  export default TeamCapSummary;
  