import React from 'react';
import PlayerNamePlate from './PlayerNamePlate';
import WWIcon from '../../shared/assets/WW.png';

const RosterPlayerRow = ({ player }) => {
    return (
      <tr>
        <td className="name-td">
            <PlayerNamePlate 
                player={player}
                icon={player.player_added ? WWIcon : undefined}
            />
        </td>
        <td>{player.salary}</td>
        <td>{player.contract_length}</td>
      </tr>
    );
};

export default RosterPlayerRow;
