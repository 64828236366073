import './App.css';

import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import League from './routes/League/League';
import Team from './routes/Team/Team';
import ContractList from './routes/ContractList/ContractList';
import PendingFAList from './routes/PendingFAList/PendingFAList';
import NotFoundPage from './routes/shared/NotFoundPage';

import Footer from './routes/shared/Footer';
import WeeklySchedule from './routes/WeeklySchedule/WeeklySchedule';


function App() {
  return (
    <Router>
      <div className='container'>
          <Routes>
            <Route path="/league/:league_key" element={<League />} />
            <Route path="/league/:league_key/:league_team_id" element={<Team />} />
            <Route path= "/league/:league_key/contract_list" element={<ContractList />} />
            <Route path= "/league/:league_key/pending_fa" element={<PendingFAList />} />
            <Route path="/:start_date?" element ={<WeeklySchedule/>} />
  

            {/* 404 Page */}
            <Route component={NotFoundPage} />
          </Routes>
          <Footer />
      </div>
    </Router>
  );
}

export default App;
