// Navbar.js
import React from 'react';
import logo from './assets/TBFHL_logo.png';
import './css/LeagueNavbar.css' 

const LeagueNavbar = ({teams, league_key}) => {
    const imageStyle = {
        width: '160px',  // Adjust the width as needed
        height: 'auto',  // Auto-adjust the height to maintain aspect ratio
        marginRight: '10px',  // Add any other CSS properties you need
        marginLeft: '10px'
        };


  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
      <a className="navbar-brand" href={`/league/${league_key}`}>
        <img src={logo} style={imageStyle} alt="TBFHL" />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="teamDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Teams
                        </a>
                          <div className="dropdown-menu" aria-labelledby="teamDropdown">
                          {teams?.map((team, index) => (
                            <a
                              key={index}
                              className="dropdown-item"
                              href={`/league/${team.league_key}/${team.league_team_id}`} // Generate the href
                            >
                              {/* Display the team logo as an image */}
                              <img
                                src={team.team_logo_url}
                                alt={team.team_name}
                                style={{
                                  width: '20px', // Adjust the size of the circular image as needed
                                  height: '20px',
                                  borderRadius: '50%', // Make the image circular
                                  marginRight: '10px', // Add spacing between the image and team name
                                }}
                              />
                              {team.team_name}
                            </a>
                          ))}
                        </div>
                    </li>
          <li className="nav-item">
            <a className="nav-link" href={`/league/${league_key}/contract_list`}>Contracts</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={`/league/${league_key}/pending_fa`}>Pending Free Agents</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/">Schedule</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default LeagueNavbar;
