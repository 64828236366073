// Import the CSS
import './WeeklySchedule.css';
import React, { useState, useEffect } from 'react';
// Import navbar
import NavBar from '../shared/Navbar';

const WeeklySchedule = () => {
  const [weeklySchedule, setWeeklySchedule] = useState(null);
  const [ui_data, setUIData] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const fetchData = async (newStartDate = null) => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/weekly-schedule${newStartDate ? '/' + newStartDate : ''}`;
    console.log("Fetching data from:", apiUrl);
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      setWeeklySchedule(data.weekly_schedule_html_table);
      setUIData(data.ui_data);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  useEffect(() => {
    fetchData(startDate);
  }, [startDate]);

  const handlePrevWeek = () => {
    if (ui_data) {
      setStartDate(ui_data.prev_week);
    }
  };

  const handleNextWeek = () => {
    if (ui_data) {
      setStartDate(ui_data.next_week);
    }
  };

  return (
    <div>
      <NavBar />
      <div className="weekly-schedule-container">
        <div className="nav-buttons">
          <button className="btn btn-dark" onClick={handlePrevWeek}>
            Previous Week
          </button>
          <button className="btn btn-dark" onClick={handleNextWeek}>
            Next Week
          </button>
        </div>
        <div dangerouslySetInnerHTML={{ __html: weeklySchedule }} />
      </div>
    </div>
  );
};

export default WeeklySchedule;
