import React from 'react';
import logo from './assets/TBFHL_logo.png';
import './css/LeagueNavbar.css' 

const NavBar = () => {
  
  const imageStyle = {
    width: '160px',  // Adjust the width as needed
    height: 'auto',  // Auto-adjust the height to maintain aspect ratio
    marginRight: '10px',  // Add any other CSS properties you need
    marginLeft: '10px'
    };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
      <a className="navbar-brand" href="/">
        <img src={logo} style={imageStyle} alt="TBFHL" />
      </a>
      {/* 
      Uncomment and modify the sections below to add more navbar items later.

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link" href="#">Your Link Here</a>
          </li>
          ... 
        </ul>
      </div>
      */}
    </nav>
  );
}

export default NavBar;
