// Footer.js
import React from 'react';
import logo from './assets/TBFHL.png';
import './css/Footer.css'

// frontend\src\routes\shared\css\footer.css

const Footer = () => {

    const imageStyle = {
        width: '80px',  // Adjust the width as needed
        height: 'auto',  // Auto-adjust the height to maintain aspect ratio
        marginRight: '10px',  // Add any other CSS properties you need
        marginLeft: '10px',
        };


  return (
    <div id="footer_box">
        <hr/>
        <div id="footer_container">
        
            <div id="footer_left">
                <div id="footer_logo">
                    <a href='/league/419.l.46668'>
                        <img src={logo} style = {imageStyle} alt="TBFHL" />
                    </a>
                </div>
            </div>
            <div id="footer_right">
                <div id="footer_support">
                    <a href="mailto: fantasyanalyzer420@gmail.com">Contact</a>
                    &#160;&#160;|&#160;&#160;
                    <a href="">GitHub</a>
                </div>

                <div id="copy_right">
                    <p>&copy; 2025 TBFHL</p>
                </div>
            </div>
        </div>
    </div>

  );
}

export default Footer;