import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import ContractListPlayerRow from './components/ContractListPlayerRow';
import LeagueNavbar from '../shared/LeagueNavbar';
import './ContractList.css';

const ContractList = () => {
  const { league_key } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    // Updated API endpoint for contract list
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/league/${league_key}/contract_list`;
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        setData(data);
        console.log(data);
      });
  }, [league_key]);

  return (
    <div>
      {/* Conditionally render LeagueNavbar */}
      {data && <LeagueNavbar teams={data.teams} league_key={league_key} />}

      <div className='page-content'>

        {/* Display the contract list */}
        <div>
          
          <Table bordered hover className='table'>
            <thead>
              <tr>
                <th className="name-th">
                  Player
                </th>
                <th>
                  Years
                </th>
                <th>
                  Salary
                </th>
                <th>
                  Team
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Using ContractListPlayerRow to display players */}
              {data?.contract_list?.map((player_contract) => (
                <ContractListPlayerRow key={player_contract.player_key} player={player_contract} />
              ))}
            </tbody>
          </Table>
        </div>

      </div>
    </div>
  );
};

export default ContractList;
