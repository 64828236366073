import React from 'react';
import './PlayerNamePlate.css';  // Import your CSS file here

const PlayerNamePlate = ({ player, icon }) => {
  const yahoo_key = player.player_key.split('.').pop();

  return (
    <div className="player-container">
      <img 
        src={player.headshot_url} 
        alt={player.name} 
        className="player-image"
      />
      <div>
        <div className="player-info"> 
          <a href={`https://sports.yahoo.com/nhl/players/${yahoo_key}`}>
            {player.name}
          </a>
          {icon && (
            <div className="icon">
              <img src={icon} alt="circle icon" />
            </div>
          )}
        </div>
        <div className="player-details">
          <b>{player.team_abr} - {player.pos.join(', ')}</b>
        </div>
      </div>
    </div>
  );
};

export default PlayerNamePlate;
